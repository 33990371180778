import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/react';

class ErrorHandler extends React.Component<any, { hasError: boolean }> {
	constructor(props: any) {
		super(props);

		this.state = { hasError: false };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		this.setState({ hasError: true });
		Sentry.captureException(error);
	}

	render(): ReactNode {
		const { hasError } = this.state;

		// after 1s reload the page
		setTimeout(() => {
			if (this.state.hasError) window.location.reload();
		}, 1000);

		if (hasError) {
			return (
				<h1>Something went wrong.</h1>
			);
		}

		return this.props.children;
	}
}

export default ErrorHandler;
