const dummyStorage = {
	storage: {} as Record<string, string>,
	getItem(k: string) {
		return this.storage[k] ?? null;
	},
	setItem(k: string, v: string) {
		this.storage[k] = v;
	},
};

// on some mobile embedded browsers localStorage is not available
export const localStorage = window.localStorage ?? dummyStorage;

if (!window.localStorage) {
	console.error('local storage is unavailable falling back to dummy storage');
}
