import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { BrowserRouter } from 'react-router-dom';

import App from './App';

import './index.css';

Sentry.init({
	dsn: import.meta.env.VITE_SENTRY_DSN,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<React.StrictMode>
		<AudioContextForcer />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
);

function AudioContextForcer() {
	// ensure audio context is always active, so we don't run into autoplay issues
	return <audio src="/silence.mp3" autoPlay loop />;
}
