import { lazy } from 'react';
import { localStorage } from '../utility/local-storage';

export const LazyLoadWithRefresh = (componentPromise) =>
	lazy(async () => {
		const requirePageRefresh = JSON.parse(localStorage.getItem('require_page_refresh') || 'false');

		try {
			const obj = await componentPromise();

			localStorage.setItem('require_page_refresh', 'false');

			return obj;
		} catch (error) {
			if (!requirePageRefresh) {
				console.log('Page not latest version. Refreshing.');
				localStorage.setItem('require_page_refresh', 'true');
				return window.location.reload();
			}

			throw error;
		}
	});
