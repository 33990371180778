import { Suspense } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import ErrorHandler from './ErrorHandler';

import { LazyLoadWithRefresh } from './components/LazyLoadWithRefresh';
import { FrontlinkProvider } from 'frontlink';

const App = () => {
	const LazyStreamOverlay = LazyLoadWithRefresh(() => import('./views/StreamOverlay/StreamOverlay'));
	const LazyStreamFrame = LazyLoadWithRefresh(() => import('./views/StreamFrame/StreamFrame'));

	const dummy = () => <span></span>;

	let { pathname } = useLocation();
	const streamKey = pathname.split('/').pop();

	return (
		<Suspense fallback={null}>
			<ErrorHandler>
				<Routes>
					{/* route for OBS cache-busting with an iframe redirecting to the routes below */}
					<Route path="/stream-overlay/:streamType/:streamKey" element={<LazyStreamOverlay />} />

					<Route
						path="/stream-frame/fullscreen/:streamKey"
						element={
							<FrontlinkProvider
								api={`${import.meta.env.VITE_BACKEND_API.replace('http', 'ws')}/frontlink_stream?key=${streamKey}&src=overlay`}
								debugLog={/localhost|127.0.0.1/.test(window.location.hostname)}
							>
								<LazyStreamFrame />
							</FrontlinkProvider>
						}
					/>

					{/* ensure old setups don't show anything wrong */}
					<Route path="/stream-frame/:streamType/:streamKey" element={dummy()} />
					<Route path="/stream-elements/:streamKey" element={dummy()} />
				</Routes>
			</ErrorHandler>
		</Suspense>
	);
};

export default App;
